import { createApi } from '@reduxjs/toolkit/query/react';

import { objectToQueryString } from '../../../lib/helpers';
import { iotCloudBaseQueryWithReauth } from '../baseQueries';
import {
  GetHemsSettingsAuditArgs,
  GetHemsSettingsAuditResult,
  GetInstallationDeletionsResult,
  GetInstallationDeletionStatusResult,
  GetInstallationPowerNowResult,
  GetInstallationsArgs,
  GetInstallationsResult,
  getIoTCloudKpisResult,
  HemsSettings,
  PatchHemsSettingsArgs,
} from './types';

const iotCloudApi = createApi({
  baseQuery: iotCloudBaseQueryWithReauth,
  reducerPath: 'iotCloudApi',
  tagTypes: [
    'hemsSettings',
    'installationStatus',
    'installationDeletion',
    'installationDeletionStatus',
  ],
  endpoints: b => ({
    getIotCloudVersion: b.query<string, void>({
      query: () => ({
        url: 'api/v1/version',
        responseHandler: 'text',
      }),
    }),
    getIoTCloudKpis: b.query<getIoTCloudKpisResult, void>({
      query: () => 'api/v1/private/kpi',
    }),
    getInstallations: b.query<GetInstallationsResult, GetInstallationsArgs>({
      query: args =>
        `api/v1/private/installation${objectToQueryString({
          ...args,
          withTotal: true,
        })}`,
    }),
    getInstallationDeletions: b.query<GetInstallationDeletionsResult, void>({
      providesTags: ['installationDeletion'],
      query: () => 'api/v1/private/installation/deletion',
    }),
    deleteInstallation: b.mutation<void, string>({
      invalidatesTags: [
        'installationStatus',
        'installationDeletion',
        'installationDeletionStatus',
      ],
      query: installationId => ({
        method: 'DELETE',
        url: `api/v1/private/installation/${installationId}`,
      }),
    }),
    getInstallationDeletionStatus: b.query<
      GetInstallationDeletionStatusResult,
      string | number
    >({
      providesTags: ['installationDeletionStatus'],
      query: installationId =>
        `api/v1/private/installation/${installationId}/deletionStatus`,
    }),
    getInstallationPowerNow: b.query<GetInstallationPowerNowResult, string>({
      query: installationUuid =>
        `api/v1/installation/${installationUuid}/now/all/power`,
    }),
    getInstallationHemsSettings: b.query<HemsSettings, string>({
      query: installationId =>
        `api/v1/private/installation/${installationId}/hemsSetting`,
      providesTags: ['hemsSettings'],
    }),
    patchInstallationHemsSettings: b.mutation<
      HemsSettings,
      PatchHemsSettingsArgs
    >({
      query: ({ installationId, body }) => ({
        url: `api/v1/private/installation/${installationId}/hemsSetting`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['hemsSettings'],
    }),
    getInstallationHemsSettingsAudit: b.query<
      GetHemsSettingsAuditResult,
      GetHemsSettingsAuditArgs
    >({
      query: params =>
        `api/v1/private/hemsSetting/audit${objectToQueryString({
          ...params,
          withTotal: true,
          sort: 'change_at',
          order: 'desc',
        })}`,
    }),
  }),
});

export const {
  useGetIotCloudVersionQuery,
  useGetIoTCloudKpisQuery,
  useGetInstallationsQuery,
  useGetInstallationDeletionsQuery,
  useDeleteInstallationMutation,
  useGetInstallationDeletionStatusQuery,
  useGetInstallationPowerNowQuery,
  useGetInstallationHemsSettingsQuery,
  usePatchInstallationHemsSettingsMutation,
  useGetInstallationHemsSettingsAuditQuery,
} = iotCloudApi;

export default iotCloudApi;
