import { Button, Grid } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import { InstallationDetailTabs } from '..';
import {
  useGetInstallationDeletionStatusQuery,
  useGetInstallationHemsSettingsQuery,
} from '../../../../redux/api/iotCloud';
import { useGetProjectServiceDataQuery } from '../../../../redux/api/montageApi';
import InstallationActions from '../InstallationActions';
import LoadingCard from '../LoadingCard';
import InstallationDataSection from '../InstallationDataSection';
import InstallationStatus from '../InstallationStatus';
import {
  getAmpereIqDataRows,
  getCustomerDataRows,
  getEkdIdDataRows,
  getEmergencyPowerReserveRows,
  getPriceBasedChargingRows,
  getProjectDataRows,
  getSolarBasedCarChargingRows,
} from '../helper';
import InstallationDeletionCard from '../../MainPage/InstallationDeletionCard';
import { SimpleJobState } from '../../../../redux/api/iotCloud/typeCopy';

type InstallationOverviewTabProps = {
  projectId?: string;
  installationId?: string;
  onTabChange: (tab: InstallationDetailTabs) => void;
};

const InstallationOverviewTab = ({
  projectId,
  installationId,
  onTabChange,
}: InstallationOverviewTabProps) => {
  const { t } = useTranslation('translation');

  const { data: installationDeletionStatus } =
    useGetInstallationDeletionStatusQuery(installationId ?? skipToken);
  const { data: serviceData, isLoading: isServiceDataLoading } =
    useGetProjectServiceDataQuery(projectId ?? skipToken);
  const { data: hemsSettingsData, isLoading: isHemsSettingsDataLoading } =
    useGetInstallationHemsSettingsQuery(installationId ?? skipToken);

  const customerDataRows = getCustomerDataRows(serviceData?.customer, t);
  const ekdIdDataRows = getEkdIdDataRows(serviceData?.customer?.ekdIdStatus, t);
  const projectDataRows = getProjectDataRows(projectId ?? '', serviceData, t);
  const ampereIqDataRows = getAmpereIqDataRows(serviceData?.installation, t);
  const priceBasedChargingDataRows = getPriceBasedChargingRows(
    hemsSettingsData,
    t,
  );
  const solarBasedCarChargingDataRows = getSolarBasedCarChargingRows(
    hemsSettingsData,
    t,
  );
  const emergencyPowerReserveDataRows = getEmergencyPowerReserveRows(
    hemsSettingsData,
    t,
  );

  const goToHemsTab = () => {
    onTabChange('hems');
  };

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12}>
        <LoadingCard>
          <InstallationStatus installationId={installationId} />
        </LoadingCard>
      </Grid>
      {installationDeletionStatus?.state && (
        <Grid item xs={12}>
          <InstallationDeletionCard
            title={t(
              'servicePortal.installationDeletion.titleInstallationInDeletion',
            )}
            highlight={status =>
              `${status.installationId}` === `${installationId}`
            }
            filter={status =>
              `${status.installationId}` === `${installationId}` ||
              [SimpleJobState.queued, SimpleJobState.processing].includes(
                status.state ?? '',
              )
            }
          />
        </Grid>
      )}
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isServiceDataLoading}>
          <InstallationDataSection
            title={t('servicePage.installationData.title')}
            rows={ampereIqDataRows}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isServiceDataLoading}>
          <InstallationActions
            projectId={projectId}
            installationId={installationId}
            serviceData={serviceData}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isServiceDataLoading}>
          <InstallationDataSection
            title={t('servicePage.customerData.title')}
            rows={customerDataRows}
          />
          <InstallationDataSection
            title={t('servicePage.customerAccountData.title')}
            rows={ekdIdDataRows}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isServiceDataLoading}>
          <InstallationDataSection
            title={t('servicePage.projectData.title')}
            rows={projectDataRows}
          />
        </LoadingCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <LoadingCard isLoading={isHemsSettingsDataLoading}>
          <InstallationDataSection
            title={t(
              'servicePortal.installationDetailsPage.priceBasedCharging',
            )}
            rows={priceBasedChargingDataRows}
          />
          <InstallationDataSection
            title={t(
              'servicePortal.installationDetailsPage.solarBasedCarCharging',
            )}
            rows={solarBasedCarChargingDataRows}
          />
          <InstallationDataSection
            title={t(
              'servicePortal.installationDetailsPage.emergencyPowerReserve',
            )}
            rows={emergencyPowerReserveDataRows}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={goToHemsTab}
            sx={{ mt: 1, alignSelf: 'center' }}
          >
            {t('servicePortal.installationDetailsPage.toHemsSettings')}
          </Button>
        </LoadingCard>
      </Grid>
    </Grid>
  );
};

export default InstallationOverviewTab;
