import {
  Box,
  LinearProgress,
  Pagination,
  Stack,
  Table,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetInstallationHemsSettingsAuditQuery } from '../../../../redux/api/iotCloud';
import LoadingCard from '../LoadingCard';
import HemsSettingsAuditTableBody from './HemsSettingsAuditTableBody';
import HemsSettingsAuditTableHeaders from './HemsSettingsAuditTableHeaders';

const LIMIT = 20;

type HemsSettingsAuditTableProps = {
  installationId?: string;
};

const HemsSettingsAuditTable = ({
  installationId,
}: HemsSettingsAuditTableProps) => {
  const { t } = useTranslation('translation');

  const [offset, setOffset] = useState(0);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(new Date());

  const { data, isLoading, isFetching } =
    useGetInstallationHemsSettingsAuditQuery(
      {
        installationId: installationId ?? '',
        changedAtAfter: fromDate?.toISOString() ?? undefined,
        changedAtBefore: toDate?.toISOString() ?? undefined,
        limit: LIMIT,
        offset: offset,
      },
      { skip: !installationId },
    );

  const handleFromDateChange = (date: Date | null) => {
    setFromDate(date);
    setOffset(0);
  };
  const handleToDateChange = (date: Date | null) => {
    setToDate(date);
    setOffset(0);
  };
  const handlePageChange = (_: unknown, page: number) => {
    setOffset((page - 1) * LIMIT);
  };

  return (
    <LoadingCard isLoading={isLoading}>
      <Typography variant="h5" color="secondary.main">
        {t('servicePortal.installationDetailsPage.hemsSettingsChangeHistory')}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="row" gap={2}>
          <DatePicker
            maxDate={subDays(toDate ?? new Date(), 1)}
            disableFuture
            label={t('common.fromDateLabel')}
            onChange={handleFromDateChange}
            value={fromDate}
          />
          <DatePicker
            label={t('common.toDateLabel')}
            onChange={handleToDateChange}
            minDate={fromDate ? addDays(fromDate, 1) : undefined}
            maxDate={new Date()}
            value={toDate}
          />
        </Stack>
      </LocalizationProvider>
      <Box
        sx={{
          mb: -2,
          height: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isFetching && <LinearProgress />}
      </Box>
      <Table>
        <HemsSettingsAuditTableHeaders />
        {data?.data && <HemsSettingsAuditTableBody data={data.data} />}
      </Table>
      <Pagination
        sx={{ display: 'flex', justifyContent: 'center' }}
        count={Math.ceil((data?.meta.total ?? 0) / LIMIT)}
        page={Math.ceil(offset / LIMIT) + 1}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        hidePrevButton
        hideNextButton
      />
    </LoadingCard>
  );
};

export default HemsSettingsAuditTable;
