import { Grid } from '@mui/material';
import ServicePortalLayout from '../ServicePortalLayout';
import InstallationDeletionCard from './InstallationDeletionCard';
import KpiDashboardCard from './KpiDashboardCard';

const MainPage = () => {
  return (
    <ServicePortalLayout>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} xl={6}>
          <KpiDashboardCard />
        </Grid>
        <Grid item sm={12} xl={6}>
          <InstallationDeletionCard />
        </Grid>
      </Grid>
    </ServicePortalLayout>
  );
};

export default MainPage;
