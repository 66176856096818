import { useTranslation } from 'react-i18next';
import { useGetIoTCloudKpisQuery } from '../../../redux/api/iotCloud';
import LoadingCard from '../InstallationDetailsPage/LoadingCard';
import { Badge, Chip, Divider, Stack } from '@mui/material';
import { formatTimestamp } from '../../../lib/formatting';
import { useNavigate } from 'react-router-dom';

const KpiDashboardCard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.dashboardKpiCard',
  });
  const navigate = useNavigate();
  const { data, isFetching } = useGetIoTCloudKpisQuery(undefined, {
    pollingInterval: 300000, // 5min
    skipPollingIfUnfocused: true,
  });

  const kpis = [
    {
      key: 'installationCount',
      label: t('installationCount', { value: data?.installationCount }),
      linkTo: '/servicePortal/installation',
    },
    {
      key: 'installationWithOwner',
      label: t('installationWithOwnerCount', {
        value: data?.installationWithOwnerCount,
      }),
      value:
        data?.installationWithOwnerCount && data?.installationCount
          ? `${Math.round(
              (data?.installationWithOwnerCount / data?.installationCount) *
                100,
            )}%`
          : undefined,
      linkTo: '/servicePortal/installation',
    },
    {
      key: 'currentElectricityPrice',
      label: t('currentElectricityPrice', {
        value: data?.currentElectricityPrice.value,
      }),
      value: formatTimestamp(data?.currentElectricityPrice.timestamp, {
        date: false,
        seconds: false,
      }),
    },
  ];

  return (
    <LoadingCard
      isLoading={!data && isFetching}
      isLoadingWithContent={isFetching}
      title={t('title')}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        {kpis.map(kpi => (
          <Badge
            key={kpi.key}
            color="secondary"
            badgeContent={kpi.value}
            max={99999}
          >
            <Chip
              label={kpi.label}
              onClick={kpi.linkTo ? () => navigate(kpi.linkTo) : undefined}
              sx={{
                '& .MuiChip-label': {
                  whiteSpace: 'normal',
                  fontWeight: 'bold',
                },
              }}
            />
          </Badge>
        ))}
      </Stack>
    </LoadingCard>
  );
};

export default KpiDashboardCard;
