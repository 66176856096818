import { Grid } from '@mui/material';
import LoadingCard from '../LoadingCard';
import PriceSignalSection from '../PriceSignalSection';

type InstallationPriceSignalTabProps = {
  installationId?: string;
};

const InstallationPriceSignalTab = ({
  installationId,
}: InstallationPriceSignalTabProps) => {
  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12}>
        <LoadingCard>
          <PriceSignalSection installationId={installationId} />
        </LoadingCard>
      </Grid>
    </Grid>
  );
};

export default InstallationPriceSignalTab;
