import {
  Box,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { formatDistance } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDateLocale } from '../../../context/TranslationContext/useDateLocale';
import { formatTimestamp } from '../../../lib/formatting';
import { useGetInstallationStatusQuery } from '../../../redux/api/montageApi';
import IconWithTooltip from '../../common/IconWithTooltip';
import { transformInstallationStatusData } from './helper';

interface InstallationStatusTooltipIconProps {
  tooltipColor: string;
  tooltipTranslationKey: string;
  lastReportDate?: string | null;
  showLastReportDate: boolean;
}

const InstallationStatusTooltipIcon = ({
  tooltipColor,
  lastReportDate,
  tooltipTranslationKey,
  showLastReportDate,
}: InstallationStatusTooltipIconProps) => {
  const { t } = useTranslation('translation');
  const dateLocale = useDateLocale();

  return (
    <IconWithTooltip
      tooltipColor={tooltipColor}
      titleComponent={
        <Box sx={{ p: 0.5 }}>
          <Typography
            variant="caption"
            sx={{ fontWeight: 700, display: 'block' }}
            color="common.white"
          >
            {t(tooltipTranslationKey)}
          </Typography>
          {showLastReportDate && (
            <Typography
              variant="caption"
              sx={{ fontSize: '0.625rem' }}
              color="common.white"
            >
              {`${t('servicePage.statusBar.lastStatusReportLabel')} ${
                lastReportDate
                  ? `${formatDistance(new Date(lastReportDate), new Date(), {
                      locale: dateLocale,
                      addSuffix: true,
                      includeSeconds: true,
                    })} @ ${formatTimestamp(lastReportDate)}`
                  : '-'
              }`}
            </Typography>
          )}
        </Box>
      }
    />
  );
};

type InstallationStatusProps = {
  installationId?: string;
};

const InstallationStatus = ({ installationId }: InstallationStatusProps) => {
  const { t, i18n } = useTranslation('translation');

  const [now, setNow] = useState(new Date());

  const {
    data: installationStatusData,
    isLoading,
    isFetching,
    fulfilledTimeStamp,
  } = useGetInstallationStatusQuery(installationId ?? skipToken, {
    pollingInterval: 15000,
    skipPollingIfUnfocused: true,
  });

  const {
    color: statusColor,
    statusLabelTranslationKey,
    tooltipTranslationKey,
    showLastReportDate,
  } = transformInstallationStatusData(installationStatusData);

  useEffect(() => {
    setNow(new Date());
    const nowInterval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(nowInterval);
  }, [fulfilledTimeStamp]);

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Typography variant="h5" color="secondary.main">
          {t('servicePage.statusBar.statusLabel')}
        </Typography>
        {!isLoading && (
          <>
            <Typography
              color={statusColor}
              sx={{ ml: 1, mr: 0.5, fontWeight: 700 }}
            >
              {t(statusLabelTranslationKey)}
            </Typography>
            <InstallationStatusTooltipIcon
              tooltipTranslationKey={tooltipTranslationKey}
              showLastReportDate={showLastReportDate}
              tooltipColor={statusColor}
              lastReportDate={installationStatusData?.lastRequest}
            />
          </>
        )}
        {isLoading && <Skeleton sx={{ ml: 2 }} width={150} />}
        <Box display="flex" justifyContent="flex-end" sx={{ flex: 1 }}>
          {!!fulfilledTimeStamp && !isLoading && (
            <Typography color="secondary.main" align="right">
              {!i18n.language.startsWith('en') ? 'vor ' : ''}
              {Math.round(
                (now.getTime() - new Date(fulfilledTimeStamp).getTime()) / 1000,
              )}
              {i18n.language.startsWith('en') ? 's ago' : 's'} @{' '}
              {formatTimestamp(fulfilledTimeStamp)}
            </Typography>
          )}
          {isLoading && <Skeleton width={200} />}
        </Box>
      </Stack>
      <LinearProgress
        variant={isFetching ? 'indeterminate' : 'determinate'}
        value={100}
      />
    </Stack>
  );
};

export default InstallationStatus;
